import React from "react";

class Fot extends React.Component {
	render() {
		return (
			<div>
				<footer className="page-footer font-small blue">
					<div className="footer-copyright text-center py-3">
						<p> © 2020 Copyright: QG3</p>
					</div>
				</footer>
			</div>
		);
	}
}

export default Fot;